<template>
  <CForm novalidate>
    <CCard no-header>
      <CCardBody>
        <h3>
          {{ operationName }} External Experience Row -
          {{ this.experienceRow.partnerIntegration?.name }}
        </h3>
        <hr />
        <CAlert :color="alertType" v-if="message">
          {{ message }}
        </CAlert>
        <div>
          <CRow class="justify-content-end">
            <div style="margin-right: 1rem; text-align: right">
              <div>Active</div>
              <CSwitch color="success" size="lg" :checked.sync="experienceRow.active" style="align-self: flex-end">
              </CSwitch>
            </div>
          </CRow>
          <CRow>
            <CCol sm="4">
              <label style="width: 95%">Name
                <a @click="
                  showAllDescriptionTranslations =
                  !showAllDescriptionTranslations
                  ">
                  <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                </a>
              </label>

              <div class="form-group">
                <div class="input-group" v-if="experienceRow.descriptionTranslations[0]"
                  v-show="!showAllDescriptionTranslations">
                  <div class="input-group-prepend">
                    <span v-text="experienceRow.descriptionTranslations[0].languageCode
                      " class="input-group-text" v-show="!showAllDescriptionTranslations" />
                  </div>
                  <input id="name" name="name" type="text" class="form-control"
                    v-model="experienceRow.descriptionTranslations[0].name" />
                </div>
              </div>
              <div class="form-group" v-show="showAllDescriptionTranslations"
                v-for="(language, k) in experienceRow.descriptionTranslations" :key="k">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span v-text="language.languageCode" class="input-group-text" />
                  </div>
                  <input type="text" class="form-control" @id="language.languageCode"
                    v-model="experienceRow.descriptionTranslations[k].name" />
                </div>
              </div>
            </CCol>
            <CCol col="3">
              <h4>
                <CIcon name="cil-check" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Options / Visibility
              </h4>
              <CRow>
                <CCol>
                  <div class="form-check" style="align-items: flex-start">
                    <div style="margin: 0.25rem">
                      <input v-model="experienceRow.type" :value="0" type="radio" name="visibilityRadio"
                        id="visibilityShowRow" />
                      <label class="form-check-label" for="visibilityShowRow" style="margin-left: 0.2rem">
                        Visible</label>
                    </div>
                    <div style="margin: 0.25rem">
                      <input v-model="experienceRow.type" :value="2" type="radio" name="visibilityRadio"
                        id="visibilityHideRow" />
                      <label class="form-check-label" for="visibilityHideRow" style="margin-left: 0.2rem">
                        Hidden</label>
                    </div>
                    <div style="margin: 0.25rem">
                      <input v-model="experienceRow.type" :value="3" type="radio" name="visibilityRadio"
                        id="visibilityOption" />
                      <label class="form-check-label" for="visibilityOption" style="margin-left: 0.2rem">
                        Option</label>
                    </div>
                    <div style="margin: 0.25rem">
                      <input v-model="experienceRow.type" :value="1" type="radio" name="visibilityRadio"
                        id="visibilityOption" />
                      <label class="form-check-label" for="visibilityOption" style="margin-left: 0.2rem">
                        Add-on</label>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCol>
            <CCol col="2">
              <CInput label="Sort order" v-model="experienceRow.sortOrder" />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4">
              <label style="width: 95%">Specification
                <a @click="
                  showAllSpecificationTranslations = !showAllSpecificationTranslations
                  ">
                  <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                </a>
              </label>

              <div class="form-group">
                <div class="input-group" v-if="experienceRow.specificationTranslations[0]"
                  v-show="!showAllSpecificationTranslations">
                  <div class="input-group-prepend">
                    <span v-text="experienceRow.specificationTranslations[0].languageCode
                      " class="input-group-text" v-show="!showAllSpecificationTranslations" />
                  </div>
                  <textarea :disabled="experienceRow.appendSpecification" id="name" name="name" type="text"
                    class="form-control" v-model="experienceRow.specificationTranslations[0].name" />
                </div>
              </div>
              <div class="form-group" v-show="showAllSpecificationTranslations"
                v-for="(language, k) in experienceRow.specificationTranslations" :key="k">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span v-text="language.languageCode" class="input-group-text" />
                  </div>
                  <textarea :disabled="experienceRow.appendSpecification" type="text" class="form-control"
                    @id="language.languageCode" v-model="experienceRow.specificationTranslations[k].name" />
                </div>
              </div>
            </CCol>
            <CCol col="8">
              <CRow>
                <CCol col="12" class="d-flex flex-row align-items-center">
                  <CIcon name="cil-camera" style="margin-right: 10px" />
                  <h4>Thumbnails and images</h4>
                </CCol>
              </CRow>
              <images-uploader :thumbnails.sync="thumbnails"></images-uploader>
            </CCol>
          </CRow>


          <CRow>
            <CCol>
              <div class="form-check form-check-inline">
                <input type="checkbox" class="form-check-input" v-model="experienceRow.appendSpecification"
                  id="appendExternalSpecification" name="appendExternalSpecification" />
                <label style="white-space: nowrap" class="form-check-label" for="appendExternalSpecification">
                  Append external specification</label>
              </div>
            </CCol>
          </CRow>
          <CRow style="align-items: flex-end">
            <CCol col="3">
              <CSelect label="Start Day" :options="weekDays" :value.sync="experienceRow.startDay">
              </CSelect>
            </CCol>
            <CCol col="3">
              <CSelect label="Tax Class" :plain="true" :options="taxClasses" :value.sync="experienceRow.taxClassId">
              </CSelect>
            </CCol>
          </CRow>
          <CRow style="align-items: flex-start">
            <CCol>
              <h4>
                <CIcon name="cil-calendar" class="text-primary"></CIcon>
                Time selection
              </h4>
              <CRow>
                <CCol> Reserve calendar time for selected day(s) </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CInput v-model="experienceRow.timeSelection.lengthInDays" label="Length in days" />
                </CCol>
                <CCol>
                  <CInput v-model="experienceRow.timeSelection.minimumLength" label="Minimum length" />
                </CCol>
              </CRow>
            </CCol>
            <CCol col="4">
              <h4>
                <CIcon name="cil-clock" class="text-primary"></CIcon> Timeslot
              </h4>
              <CRow>
                <CCol> Reserve timeslot </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CInput label="Start time" type="time" v-model="reservedTimeSlot.start"></CInput>
                </CCol>
                <CCol>
                  <CInput label="End time" type="time" v-model="reservedTimeSlot.end"></CInput>
                </CCol>
              </CRow>
            </CCol>
            <CCol col="2">
              <div v-if="experienceRow.type != 1">
                <CRow>
                  <CCol>
                    <h4>
                      <CIcon name="cil-money" style="margin-bottom: 5px" class="text-primary" />
                      Pricing
                    </h4>
                  </CCol>
                </CRow>

                <div>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.isPriceFromIntegration"
                          id="isPriceFromInteragtion" name="isPriceFromIntegration" />
                        <label style="white-space: nowrap" class="form-check-label" for="isPriceFromInteragtion">
                          Price from Integration</label>
                      </div>
                    </CCol>
                  </CRow>
                  <div v-if="experienceRow.isPriceFromIntegration == true">
                    <CRow>
                      <CCol>
                        <div class="form-check form-check-inline">
                          <input type="checkbox" class="form-check-input" v-model="experienceRow.isPricePerNight"
                            :disabled="experienceRow.isPricePerDay ||
                              experienceRow.isPricePerTotal
                              " id="isPricePerNight" name="isPricePerNight" />
                          <label class="form-check-label" for="isPricePerNight">
                            Price per night
                          </label>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <div class="form-check form-check-inline">
                          <input type="checkbox" class="form-check-input" v-model="experienceRow.isPricePerDay" :disabled="experienceRow.isPricePerNight ||
                            experienceRow.isPricePerTotal
                            " id="isPricePerDay" name="isPricePerDay" />
                          <label class="form-check-label" for="isPricePerDay">
                            Price per day
                          </label>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                  <CRow v-if="experienceRow.isPriceFromIntegration == true">
                    <CCol>
                      <CInput label="Price adjustment (%)" v-model="experienceRow.priceAdjustment" />
                    </CCol>
                  </CRow>
                </div>

                <div v-if="experienceRow.isPriceFromIntegration == false">
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.isPricePerNight" :disabled="experienceRow.isPricePerDay ||
                          experienceRow.isPricePerTotal
                          " id="isPricePerNight" name="isPricePerNight" />
                        <label class="form-check-label" for="isPricePerNight">
                          Price per night
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.isPricePerDay" :disabled="experienceRow.isPricePerNight ||
                          experienceRow.isPricePerTotal
                          " id="isPricePerDay" name="isPricePerDay" />
                        <label class="form-check-label" for="isPricePerDay">
                          Price per day
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.isPricePerTotal" :disabled="experienceRow.isPricePerNight ||
                          experienceRow.isPricePerDay
                          " id="isPricePerDay" name="isPricePerDay" />
                        <label class="form-check-label" for="isPricePerDay">
                          Price per total
                        </label>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol>
                      <CInput label="Base price" v-model="experienceRow.basePrice.amount" />
                    </CCol>
                  </CRow>
                </div>
              </div>
            </CCol>
            <CCol col="3" v-if="experienceRow.isPriceFromIntegration == false">
              <h4>
                <CIcon name="cil-calculator" class="text-primary"></CIcon> Guest
                Prices
              </h4>
              <div>
                <input type="radio" name="guestPriceRadio" id="guestPriceRadioDisable" :value="GUEST_PRICE_TYPE_NONE"
                  v-model="experienceRow.guestPriceType" />
                <label for="guestPriceRadioDisable">Disable Guest Prices</label>
              </div>
              <div>
                <input type="radio" name="guestPriceRadio" id="guestPriceRadioPerGuest"
                  :value="GUEST_PRICE_TYPE_PER_GUEST" v-model="experienceRow.guestPriceType" />
                <label for="guestPriceRadioPerGuest">Per Guest</label>
                <div v-show="experienceRow.guestPriceType === GUEST_PRICE_TYPE_PER_GUEST
                  " style="margin-left: 1rem">
                  <CInput label="Price Value" type="text" v-model="experienceRow.pricePerGuest.price" />

                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input"
                          v-model="experienceRow.pricePerGuest.isPricePerNight" :disabled="experienceRow.pricePerGuest.isPricePerDay ||
                            experienceRow.pricePerGuest.isPricePerTotal
                            " id="isPricePerNightGuest" name="isPricePerNightGuest" />
                        <label class="form-check-label" for="isPricePerNightGuest">
                          Price per night
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input"
                          v-model="experienceRow.pricePerGuest.isPricePerDay" :disabled="experienceRow.pricePerGuest.isPricePerNight ||
                            experienceRow.pricePerGuest.isPricePerTotal
                            " id="isPricePerDayGuest" name="isPricePerDayGuest" />
                        <label class="form-check-label" for="isPricePerDayGuest">
                          Price per day
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input"
                          v-model="experienceRow.pricePerGuest.isPricePerTotal" :disabled="experienceRow.pricePerGuest.isPricePerNight ||
                            experienceRow.pricePerGuest.isPricePerDay
                            " id="isPricePerDayGuest" name="isPricePerDayGuest" />
                        <label class="form-check-label" for="isPricePerDayGuest">
                          Price per total
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </div>
              <div>
                <input type="radio" name="guestPriceRadio" id="guestPriceRadioPerGuestType"
                  :value="GUEST_PRICE_TYPE_PER_GUEST_TYPE" v-model="experienceRow.guestPriceType" />
                <label for="guestPriceRadioPerGuestType">Per Guest type</label>
                <div v-show="experienceRow.guestPriceType ===
                  GUEST_PRICE_TYPE_PER_GUEST_TYPE
                  " style="margin-left: 1rem">
                  <div style="display: flex">
                    <CInput v-for="pricePerGuestType in experienceRow
                      .pricePerGuestType.guestTypePrices" :key="pricePerGuestType.guestTypeId"
                      v-model="pricePerGuestType.price" :label="pricePerGuestType.label" type="text"
                      style="margin-right: 0.5rem" />
                  </div>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.pricePerGuestType.isPricePerNight
                          " :disabled="experienceRow.pricePerGuestType.isPricePerDay ||
    experienceRow.pricePerGuestType.isPricePerTotal
    " id="isPricePerNightGuestType" name="isPricePerNightGuestType" />
                        <label class="form-check-label" for="isPricePerNightGuestType">
                          Price per night
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.pricePerGuestType.isPricePerDay
                          " :disabled="experienceRow.pricePerGuestType.isPricePerNight ||
    experienceRow.pricePerGuestType.isPricePerTotal
    " id="isPricePerDayGuestType" name="isPricePerDayGuestType" />
                        <label class="form-check-label" for="isPricePerDayGuestType">
                          Price per day
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" v-model="experienceRow.pricePerGuestType.isPricePerTotal
                          " :disabled="experienceRow.pricePerGuestType.isPricePerNight ||
    experienceRow.pricePerGuestType.isPricePerDay
    " id="isPricePerDayGuestType" name="isPricePerDayGuestType" />
                        <label class="form-check-label" for="isPricePerDayGuestType">
                          Price per total
                        </label>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <h4>
                <CIcon name="cil-list" class="text-primary"></CIcon> Custom Properties
              </h4>
            </CCol>
          </CRow>
          <CRow v-if="experienceRow.partnerIntegration">
            <CCol col="3" v-for="item in experienceRow.partnerIntegration.customFields">
              <template v-if="item.fieldType.toLowerCase() === 'input'">
                <CInput v-model="item.fieldValue" :label="item.fieldName" />
              </template>
              <template v-else-if="item.fieldType.toLowerCase() === 'dropdown'">
                <CSelect :value.sync="item.fieldValue" :label="item.fieldName" :options="[{ label: 'Choose', value: '00000000-0000-0000-0000-000000000000' }].concat(item.possibleValuesSeparatedByComma
                  .split(',')
                  .map((option) => {
                    const [label, value] = option.split('.');
                    return { label, value };
                  })
                )" />

              </template>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <h4>
                <CIcon name="cil-user" class="text-primary"></CIcon> Capacity
              </h4>
            </CCol>
          </CRow>
          <div class="form-check form-check-inline" v-if="experienceRow.partnerIntegration?.useStayifyAvailabilityMgmt ==
            false
            ">
            <input type="checkbox" class="form-check-input" v-model="experienceRow.isCapacityFromIntegration"
              id="isPriceFromInteragtion" name="isPriceFromInteragtionType" />
            <label class="form-check-label" for="isPriceFromInteragtion">
              Capacity from Integration</label>
          </div>
          <CRow v-if="experienceRow.isCapacityFromIntegration == false">
            <CCol>
              <span>Experience row</span>
              <CRow>
                <CCol col="2"></CCol>
                <CCol col="2">Disabled</CCol>
                <CCol col="2">Min</CCol>
                <CCol col="2">Max</CCol>
              </CRow>
              <CRow v-for="expRowCapac in experienceRow.experienceRowCapacity
                .guestTypeSpecificCapac">
                <CCol col="2">
                  <span>{{ expRowCapac?.label }}</span>
                </CCol>
                <CCol col="2">
                  <input v-model="expRowCapac.disabled" type="checkbox" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="expRowCapac.min" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="expRowCapac.max" />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="2">
                  <span>Total</span>
                </CCol>
                <CCol col="2"> </CCol>
                <CCol col="2">
                  <CInput v-model="experienceRow.experienceRowCapacity.minTotal" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceRow.experienceRowCapacity.maxTotal" />
                </CCol>
              </CRow>
            </CCol>
            <CCol>
              <span>Per inventory item</span>
              <CRow>
                <CCol col="2">Min</CCol>
                <CCol col="2">Max</CCol>
                <CCol col="2">Bookings</CCol>
              </CRow>
              <CRow v-for="perItemCapac in experienceRow.perInventoryItemCapacity
                .guestTypeSpecificCapac">
                <CCol col="2">
                  <CInput v-model="perItemCapac.min" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="perItemCapac.max" />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="2">
                  <CInput v-model="experienceRow.perInventoryItemCapacity.minTotal" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceRow.perInventoryItemCapacity.maxTotal" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceRow.perInventoryItemCapacity.bookings" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>

        <CRow style="padding-top: 40px">
          <CCol col="8"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton color="secondary" class="btn-lg" @click="goBack">
              <CIcon name="cil-mood-very-good" /> Back to experience
            </CButton>

            <CButton color="primary" @click="save()" style="margin-left: 15px" class="btn-lg">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
import common from "@/common"
import ImagesUploader from "@/components/ImagesUploader";

export default {
  name: "CreateExternalExperienceRow",
  components: {
    ImagesUploader
  },
  props: {
    thumbnails: [],
  },
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      experienceDayId: "00000000-0000-0000-0000-000000000000",
      experienceScheduleId: null,
      operationName: "Create New",
      GUEST_PRICE_TYPE_NONE: 1,
      GUEST_PRICE_TYPE_PER_GUEST: 2,
      GUEST_PRICE_TYPE_PER_GUEST_TYPE: 3,

      experienceRow: {
        id: "00000000-0000-0000-0000-000000000000",
        dayId: null,
        startDay: null,
        descriptionTranslations: [],
        specificationTranslations: [],
        sourceType: 2, //external
        inventoryId: null,
        inventoryItemId: null,
        taxClassId: null,
        isPricePerInstance: false,
        isPricePerDay: false,
        isPricePerNight: false,
        thumbnailUrl: null,
        priceAdjustment: null,
        partnerIntegrationId: "00000000-0000-0000-0000-000000000000",
        partnerIntegration: null,
        timeSelection: {
          lengthInDays: 0,
          minimumLength: 0,
        },
        reservedTimeSlot: {
          start: { hours: null, minutes: null },
          end: { hours: null, minutes: null },
        },
        allocation: {
          totalGuestAmount: null,
        },
        basePrice: {
          amount: 0,
        },
        discount: {
          minimumBookedDays: null,
          rate: 0,
        },
        guestPriceType: null,
        pricePerGuest: {
          price: 0,
          isDiscountAvailable: false,
          isPricePerDay: false,
          isPricePerNight: false,
          isPricePerTotal: false,
        },
        pricePerGuestType: {
          guestTypePrices: [],
        },
        perInventoryItemCapacity: {
          minTotal: null,
          maxTotal: null,
          bookings: null,
          guestTypeSpecificCapac: [],
          disabled: true,
        },
        experienceRowCapacity: {
          minTotal: null,
          maxTotal: null,
          disabled: true,
          label: null,
          guestTypeSpecificCapac: [],
        },
        type: 0,
        isPriceFromIntegration: true,
        appendSpecification: false,
        isCapacityFromIntegration: true,
        roomName: null,
        ratePlanCode: null,
        roomTypeId: null,
        active: true,
      },

      // helpers for Inventory
      showAllDescriptionTranslations: true,
      showAllSpecificationTranslations: true,
      languages: [],
      taxClasses: [],
      weekDays: [],
      reservedTimeSlot: {
        start: null,
        end: null,
      },

      // alert
      alertType: "danger",
      message: null,
    };
  },
  methods: {
    goBack(status, evt, accept) {
      this.$router.go(-1);
    },
    apiTimeToFrontendFormat(timeObj) {
      if (timeObj) {
        var timeObjReturn = "";
        if (timeObj?.hours?.toString().length == 1) {
          timeObjReturn += "0" + timeObj.hours + ":";
        } else {
          timeObjReturn += timeObj?.hours?.toString() + ":";
        }
        if (timeObj?.minutes?.toString().length == 1)
          timeObjReturn = timeObjReturn + "0" + timeObj?.minutes?.toString();
        else timeObjReturn = timeObjReturn + timeObj?.minutes?.toString();
        return timeObjReturn;
      } else {
        return "";
      }
    },
    fillExpRowData(response, field) {
      if (response[field]) {
        if (field != "reservedTimeSlot") {
          this.experienceRow[field] = response[field];
        }
      }
    },
    addGuestTypeLabel(guestTypeData, expRowData) {
      const expRowGuestTypeIdx = expRowData?.findIndex(
        (g) => g.guestTypeId === guestTypeData.value
      );
      if (expRowGuestTypeIdx > -1) {
        let modifiedData = {
          ...expRowData[expRowGuestTypeIdx],
          label: guestTypeData.label,
        };
        expRowData.splice(expRowGuestTypeIdx, 1, modifiedData); // https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
      }
      return expRowGuestTypeIdx;
    },

    initPricePerGTGuestType(guestType) {
      this.experienceRow.pricePerGuestType.guestTypePrices.push({
        label: guestType.label,
        guestTypeId: guestType.value,
        price: null,
      });
    },
    initExperienceRowCapacity(guestType) {
      const existingGuestType =
        this.experienceRow.experienceRowCapacity.guestTypeSpecificCapac.find(
          (g) => g.guestTypeId === guestType.value
        );

      if (!existingGuestType) {
        this.experienceRow.experienceRowCapacity.guestTypeSpecificCapac.push({
          disabled: false,
          min: null,
          max: null,
          guestTypeId: guestType.value,
          label: guestType.label,
        });
      }
    },
    initPerItemCapac(guestType) {
      this.experienceRow.perInventoryItemCapacity.guestTypeSpecificCapac.push({
        disabled: false,
        min: null,
        max: null,
        guestTypeId: guestType.value,
      });
    },
    getTaxClasses() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/TaxClasses`)
        .then(function (response) {
          if (response && response.data) self.taxClasses = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getWeekDays() {
      if (!this.experienceScheduleId) {
        return;
      }
      let self = this;
      axios
        .get(
          `${this.$apiAdress}/v1/ListItem/AvailableStartDays?scheduleId=${this.experienceScheduleId}`
        )
        .then(function (response) {
          if (response && response.data) self.weekDays = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    async getLanguages() {
      let self = this;
      await axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          if (response && response.data) self.languages = response.data;
          for (let language of self.languages) {
            // if (self.experienceRow.id == self.emptyGuid) {

            for (let i = 0; i < self.languages.length; i++) {
              if (self.experienceRow.descriptionTranslations.find(x => x.languageCode == self.languages[i].languageCode) == null) {
                self.experienceRow.descriptionTranslations.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });
              }

              if (self.experienceRow.specificationTranslations.find(x => x.languageCode == self.languages[i].languageCode) == null) {
                self.experienceRow.specificationTranslations.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });
              }

            }
            //}
            const existingLanguage = self.experienceRow.descriptionTranslations.find(d => d.languageId === language.value);
            if (!existingLanguage) {
              self.experienceRow.descriptionTranslations.push({
                languageCode: language.label,
                name: null,
                languageId: language.value,
              });
            }

            const existingLanguage2 = self.experienceRow.specificationTranslations.find(d => d.languageId === language.value);
            if (!existingLanguage2) {
              self.experienceRow.specificationTranslations.push({
                languageCode: language.label,
                name: null,
                languageId: language.value,
              });
            }


          }

          let test = self.experienceRow.descriptionTranslations;
          self.experienceRow.descriptionTranslations = [];
          test.map(function (value, key) {
            var exists = self.languages.find(x => x.languageCode == value.languageCode);
            if (exists != undefined) {
              self.experienceRow.descriptionTranslations.push(value);
            }
          });

          let test2 = self.experienceRow.specificationTranslations;
          self.experienceRow.specificationTranslations = [];
          test2.map(function (value, key) {
            var exists = self.languages.find(x => x.languageCode == value.languageCode);
            if (exists != undefined) {
              self.experienceRow.specificationTranslations.push(value);
            }
          });
        })

        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    async getGuestTypes() {
      let self = this;
      await axios
        .get(
          this.$apiAdress +
          "/v1/ListItem/GuestTypeList?partnerId=" +
          this.partnerId +
          "&experienceId=" +
          this.experienceId
        )
        .then((response) => {
          if (response && response.data) {
            const guestTypes = response.data;
            if (self.operationName === "Edit") {
              if (!self.experienceRow.pricePerGuestType.guestTypePrices)
                self.experienceRow.pricePerGuestType.guestTypePrices = [];
              if (
                !self.experienceRow.experienceRowCapacity.guestTypeSpecificCapac
              )
                self.experienceRow.experienceRowCapacity.guestTypeSpecificCapac =
                  [];
              if (
                !self.experienceRow.perInventoryItemCapacity
                  .guestTypeSpecificCapac
              )
                self.experienceRow.perInventoryItemCapacity.guestTypeSpecificCapac =
                  [];

              // if guestType data already exists on the experience row, add guestType labels
              for (let guestType of guestTypes) {
                const idxB = self.addGuestTypeLabel(
                  guestType,
                  self.experienceRow.pricePerGuestType.guestTypePrices
                );
                if (idxB === -1) {
                  self.initPricePerGTGuestType(guestType);
                }
                const idxC = this.addGuestTypeLabel(
                  guestType,
                  self.experienceRow.experienceRowCapacity
                    .guestTypeSpecificCapac
                );
                if (
                  idxC === -1 &&
                  !self.experienceRow.experienceRowCapacity.guestTypeSpecificCapac.some(
                    (g) => g.guestTypeId === guestType.value
                  )
                ) {
                  self.initExperienceRowCapacity(guestType);
                }

                const idxCC = this.addGuestTypeLabel(
                  guestType,
                  self.experienceRow.perInventoryItemCapacity
                    .guestTypeSpecificCapac
                );
                if (idxCC === -1) {
                  self.initPerItemCapac(guestType);
                }
              }
            } else {
              // otherwise init guestType data
              self.experienceRow.pricePerGuestType.guestTypePrices = [];
              self.experienceRow.experienceRowCapacity.guestTypeSpecificCapac =
                [];
              self.experienceRow.perInventoryItemCapacity.guestTypeSpecificCapac =
                [];

              for (let guestType of guestTypes) {
                self.initPricePerGTGuestType(guestType);
                self.initExperienceRowCapacity(guestType);
                self.initPerItemCapac(guestType);
              }
            }
          }
        })
        .catch((error) => {
                    this.alertType = "danger";
          setTimeout(function () {
              this.message = null;
          }, 10000);
          this.message = error;
        });
    },
    save() {
      let self = this;
      if (
        this.reservedTimeSlot.start != null &&
        this.reservedTimeSlot.start != ""
      ) {
        if (
          this.experienceRow.reservedTimeSlot.start == null ||
          this.experienceRow.reservedTimeSlot.start === undefined
        ) {
          this.experienceRow.reservedTimeSlot.start.hours = "";
          this.experienceRow.reservedTimeSlot.start.minutes = "";
        } else {
          var hours = this.reservedTimeSlot.start.split(":")[0];
          var minutes = this.reservedTimeSlot.start.split(":")[1];

          if (hours == "undefined") {
            hours = null;
          }

          if (minutes == "undefined") {
            minutes = null;
          }

          this.experienceRow.reservedTimeSlot.start.hours = hours;
          this.experienceRow.reservedTimeSlot.start.minutes = minutes;
        }
      } else {
        this.experienceRow.reservedTimeSlot.start = null;
      }
      if (
        this.reservedTimeSlot.end != null &&
        this.reservedTimeSlot.end != ""
      ) {
        if (
          this.experienceRow.reservedTimeSlot.end == null ||
          this.experienceRow.reservedTimeSlot.end === undefined
        ) {
          this.experienceRow.reservedTimeSlot.end.hours = "";
          this.experienceRow.reservedTimeSlot.end.minutes = "";
        } else {
          var hours = this.reservedTimeSlot.end.split(":")[0];
          var minutes = this.reservedTimeSlot.end.split(":")[1];
          if (hours == "undefined") {
            hours = null;
          }

          if (minutes == "undefined") {
            minutes = null;
          }

          this.experienceRow.reservedTimeSlot.end.hours = hours;
          this.experienceRow.reservedTimeSlot.end.minutes = minutes;
        }
      } else {
        this.experienceRow.reservedTimeSlot.end = null;
      }
      this.experienceRow.pricePerGuestType.guestTypePrices =
        this.experienceRow.guestPriceType ===
          this.GUEST_PRICE_TYPE_PER_GUEST_TYPE
          ? this.experienceRow.pricePerGuestType.guestTypePrices.map((g) => ({
            guestTypeId: g.guestTypeId,
            price: g.price,
            label: g.label,
          }))
          : [];
      let intermediarResult1 =
        this.experienceRow.experienceRowCapacity.guestTypeSpecificCapac;
      let intermediarResult2 =
        this.experienceRow.perInventoryItemCapacity.guestTypeSpecificCapac;

      this.experienceRow.experienceRowCapacity.guestTypeSpecificCapac = [];
      this.experienceRow.perInventoryItemCapacity.guestTypeSpecificCapac = [];

      if (
        self.experienceRow.timeSelection.lengthInDays === null ||
        self.experienceRow.timeSelection.lengthInDays === ""
      ) {
        self.experienceRow.timeSelection.lengthInDays = 0;
      }

      intermediarResult1.map(function (value, key) {
        value.disabled = value.disabled;
        if (value.min == "") {
          value.min = null;
        } else {
          value.min = value.min;

        }

        if (value.max == "") {
          value.max = null;
        } else {
          value.max = value.max;

        }
        value.guestTypeId = value.guestTypeId;
        self.experienceRow.experienceRowCapacity.guestTypeSpecificCapac.push(
          value
        );
      });

      if (self.experienceRow.perInventoryItemCapacity.bookings == "") {
        self.experienceRow.perInventoryItemCapacity.bookings = null;
      }

      if (self.experienceRow.experienceRowCapacity.minTotal == "") {
        self.experienceRow.experienceRowCapacity.minTotal = null;
      }

      if (self.experienceRow.perInventoryItemCapacity.minTotal == "") {
        self.experienceRow.perInventoryItemCapacity.minTotal = null;
      }

      if (self.experienceRow.perInventoryItemCapacity.minTotal == "") {
        self.experienceRow.perInventoryItemCapacity.minTotal = null;
      }

      if (self.experienceRow.perInventoryItemCapacity.maxTotal == "") {
        self.experienceRow.perInventoryItemCapacity.maxTotal = null;
      }

      if (self.experienceRow.experienceRowCapacity.maxTotal == "") {
        self.experienceRow.experienceRowCapacity.maxTotal = null;
      }

      intermediarResult2.map(function (value, key) {
        if (value.min == "") {
          value.min = null;
        }
        else {
          value.min = value.min;

        }
        if (value.max == "") {
          value.max = null;
        } else {
          value.max = value.max;

        }
        value.guestTypeId = value.guestTypeId;
        self.experienceRow.perInventoryItemCapacity.guestTypeSpecificCapac.push(
          value
        );
      });
      if (this.experienceRow.pricePerGuestType.guestTypePrices.length == 0) {
        this.experienceRow.pricePerGuestType.guestTypePrices = null;
      }

      this.experienceRow.sourceType = 2;
      this.experienceRow.ratePlanCode =
        this.experienceRow.ratePlanCode?.toString();
      this.experienceRow.roomName = this.experienceRow.roomName?.toString();
      this.experienceRow.roomTypeId = this.experienceRow.roomTypeId?.toString();

      var formData = new FormData();
      common.buildFormData(formData, this.experienceRow);
      let newImages = self.thumbnails?.filter((thumbnail) => thumbnail.file);
      let thumbnailsUrls = self.getThumbnailsUrls();
      if (newImages) {
        let newImagesOrders = [];
        newImages.forEach((newImage) => {
          formData.append("files", newImage.file);
          newImagesOrders.push({
            fileName: newImage.file.name,
            orderNumber: newImage.order,
          });
        });
        formData.append("newImagesOrdersJson", JSON.stringify(newImagesOrders));
      }

      if (thumbnailsUrls) formData.set("thumbnailUrl", thumbnailsUrls);
      axios
        .post(
          `${this.$apiAdress}/v1/ExperienceRow/${this.experienceDayId}`,
          formData
        )
        .then((response) => {
                    this.alertType = "success";
          setTimeout(function () {
              this.message = null;
          }, 5000);
          this.message = "Successfully updated experience schedule.";
          this.experienceRow.id = response.data;
        })
        .catch((error) => {
                    this.alertType = "danger";
          setTimeout(function () {
              this.message = null;
          }, 10000);
          this.message = error.response.data.errors;
        });
    },
    handleFileUpload(event) {
      this.experienceRow.file = event.target.files[0];
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    getThumbnailsUrls() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.url)
          .map((thumbnail) => {
            return thumbnail.url;
          })
          .join();
      } else return null;
    },
    getThumbnailsFiles() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.file)
          .map((thumbnail) => {
            return thumbnail.file;
          });
      } else return null;
    },
    async get() {
      let self = this;
      if (self.experienceRow.id === self.emptyGuid) {
        self.getLanguages();
        return;
      }

      await axios
        .get(
          `${this.$apiAdress}/v1/experienceRow/${self.experienceDayId}/${self.experienceRow.id}`
        )
        .then((response) => {
          console.log("resp", response)
          self.experienceRow.isPricePerDay = response.data.isPricePerDay;
          self.experienceRow.isPricePerNight = response.data.isPricePerNight;
          self.experienceRow.isPricePerInstance =
            response.data.isPricePerInstance;
          if (response.data.reservedTimeSlot != null) {
            if (response.data.reservedTimeSlot.start != null) {
              self.experienceRow.reservedTimeSlot.start =
                response.data.reservedTimeSlot.start;
            }
            if (response.data.reservedTimeSlot.end != null) {
              self.experienceRow.reservedTimeSlot.end =
                response.data.reservedTimeSlot.end;
            }
          }
          let thumbnailOrderIndex = 0;
          self.thumbnails = response.data.thumbnailUrl
            ?.split(",")
            .map((thumbnail) => {
              return {
                url: thumbnail,
                order: thumbnailOrderIndex++,
                blob: null,
                file: null,
                show: false,
              };
            });
          self.experienceRow.type = response.data.type;

          for (let key of Object.keys(response.data)) {
            self.fillExpRowData(response.data, key);
          }

          if (response.data.reservedTimeSlot != null) {
            if (response.data.reservedTimeSlot.start != null) {
              self.reservedTimeSlot.start = self.apiTimeToFrontendFormat(
                self.experienceRow.reservedTimeSlot.start
              );
            } else {
              self.reservedTimeSlot.start = "";
            }

            if (self.experienceRow.reservedTimeSlot.end != null) {
              self.reservedTimeSlot.end = self.apiTimeToFrontendFormat(
                self.experienceRow.reservedTimeSlot.end
              );
            }
          }
          // Making a deep copy of experienceRowCapacity
          self.experienceRow.experienceRowCapacity = JSON.parse(
            JSON.stringify(response.data.experienceRowCapacity)
          );

          self.getLanguages();
          self.experienceRow.isCapacityFromIntegration =
            response.data.isCapacityFromIntegration;
          if (self.experienceRow.id !== null && self.experienceRow.id !== this.emptyGuid) {
            self.operationName = "Edit";
          }
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    }
  },
  mounted: async function () {
    let self = this;
    self.experienceDayId = this.$route.params.dayId;
    self.experienceRow.dayId = self.experienceDayId;
    self.experienceRow.id = this.$route.params.id;
    self.experienceScheduleId = this.$route.query.scheduleId;
    self.experienceId = this.$route.query.experienceId;
    if (this.$route.query.integrationType != null) {
      this.experienceRow.partnerIntegrationId = this.$route.query.integrationType;
      let self = this;
      await axios
        .post(`${this.$apiAdress}/v1/ExperienceRow/${self.experienceDayId}/get-partner-integration/${self.experienceRow.partnerIntegrationId}`)
        .then(function (response) {
          self.experienceRow.partnerIntegration = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          self.message = error;
        });
     await axios
        .post(`${this.$apiAdress}/v1/ExperienceRow/${self.experienceDayId}/get-row-custom-fields/${self.experienceRow.partnerIntegrationId}`)
        .then(function (response) {
          self.experienceRow.partnerIntegration.customFields = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          self.message = error;
        });
    }

    await self.get();

    self.sleep(1000);
    self.getTaxClasses();
    self.getWeekDays();
    await self.getGuestTypes();
  },
};
</script>
